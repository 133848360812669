import React from "react";
import { Form, Input, Button, Divider } from "antd";
import ImageUpload from "./ImageUpload";
import { useState } from "react";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import CountryPhoneInput from "antd-country-phone-input";
import { SERVER_URL } from "../constants";

const { TextArea } = Input;

export default function MintForm() {
  const [ipfsURL, setIpfsURL] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");

  // useEffect(() => {
  //   const lastMint = parseInt(localStorage.getItem("lastMint"));
  //   if (Date.now() - lastMint < 24 * 60 * 60 * 1000) {
  //     setMessage("You already minted an NFT today. Please try again tomorrow.");
  //     setSubmitted(true);
  //   }
  // }, []);

  const onFinish = (values) => {
    setSubmitted(true);
    values.ipfsURL = ipfsURL;
    axios
      .post(SERVER_URL + "/mint", values)
      .then((v) => {
        if (!!v.data.hash) {
          // localStorage.setItem("lastMint", Date.now());
          const tokenID = v.data.tokenID;
          const link =
            "https://opensea.io/assets/matic/0x5fff3f381fa8bcd024257b0557a924c379bd71a2/" +
            tokenID;
          setMessage(
            <div>
              Your NFT is created and a private key is sent to your phone.{" "}
              <br />
              <br />
              <a href={link} target="_blank" rel="noreferrer">
                ONE TAP NFT #{tokenID}
              </a>
            </div>
          );
        } else {
          setMessage(
            "Sorry, there was a problem with minting your NFT. Please try again later."
          );
        }
      })
      .catch((res) => {
        if (res.response && res.response.status === 429) {
          setMessage("Rate limited. Please try again tomorrow.");
        } else {
          setMessage(
            (res.response && res.response.data && res.response.data.error) ||
              (res.response && res.response.data) ||
              "Sorry, there was a problem with minting your NFT. Please try again later."
          );
        }
      });
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ padding: 24 }}>
        <ImageUpload
          onChange={(url) => setIpfsURL(url)}
          disabledImage={!!submitted}
        />
      </div>
      {message && <h1>{message}</h1>}
      {submitted && !message && <LoadingOutlined style={{ fontSize: 60 }} />}
      {!submitted && (
        <Form name="mint" onFinish={onFinish}>
          <h2>
            Upload an image and tap <strong>Mint NFT</strong>.
          </h2>
          <Form.Item
            name="inviteCode"
            initialValue={new URL(document.location).searchParams.get("invite")}
            rules={[
              {
                required: true,
                message: "Please enter an invite code.",
              },
            ]}
          >
            <Input size="large" placeholder="Invite Code" />
          </Form.Item>
          <Form.Item
            name="phone"
            initialValue={{
              short: "us",
            }}
            rules={[
              {
                required: true,
                message: "Phone number is required to receive your NFT.",
              },
            ]}
          >
            <CountryPhoneInput placeholder="Phone Number" />
          </Form.Item>

          <Form.Item name="name">
            <Input
              size="large"
              placeholder="NFT Name (optional)"
              maxLength={64}
            />
          </Form.Item>
          <Form.Item name="description">
            <TextArea
              size="large"
              rows={2}
              placeholder="NFT Description (optional)"
              maxLength={256}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={!ipfsURL}
            >
              Mint NFT
            </Button>
          </Form.Item>
          <h2>The private key for your NFT will be sent to your phone.</h2>
          <Divider />
          <h5>
            <p>
              Limit 10 NFTs per day. IP and phone number will be recorded.
              Questions? <a href="./faq">FAQ</a>
            </p>
          </h5>
          <div style={{ height: 200 }}></div>
        </Form>
      )}
    </div>
  );
}
