import React from "react";

export default function FAQ() {
  return (
    <div>
      <h1>FAQ</h1>
      <h2>How can I claim my NFT?</h2>
      <p>
        Import your private key to a wallet (e.g.{" "}
        <a href="https://metamask.io" target="_blank" rel="noreferrer">
          MetaMask
        </a>
        ).
      </p>
      <p>
        Switch to the{" "}
        <a href="https://chainlist.org" target="_blank" rel="noreferrer">
          Polygon network
        </a>
        .
      </p>
      <p>
        {" "}
        You can sell the NFT on a marketplace like{" "}
        <a href="https://opensea.io" target="_blank" rel="noreferrer">
          OpenSea
        </a>{" "}
        or send it to your own address.
      </p>
      <h2>Where are NFTs stored?</h2>
      <p>
        NFTs are stored by{" "}
        <a href="https://nft.storage" target="_blank" rel="noreferrer">
          nft.storage
        </a>
        .
      </p>
      <h2>Who is paying for minting gas fee?</h2>
      <p>Polygon network gas fees are low and I'm paying for all gas fees.</p>
      <h2>Is sending open private keys via text messages safe?</h2>
      <p>No. </p>
      <h2>Where is the contract?</h2>
      <p>
        You can view the verified contract and all transactions{" "}
        <a
          href="https://polygonscan.com/address/0x5fff3f381fa8bcd024257b0557a924c379bd71a2"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </p>
      <h2>Who made this?</h2>
      <p>
        ONE TAP NFT was made by{" "}
        <a
          target="_blank"
          href="https://twitter.com/cagriaksay"
          rel="noreferrer"
        >
          @cagriaksay
        </a>
        .
      </p>
    </div>
  );
}
