import React from "react";
import { Image } from "antd";

export default function Logo({ title }) {
  return (
    <a href="/">
      <Image
        width={200}
        preview={false}
        alt={title}
        src={`${process.env.PUBLIC_URL}/onetapnft-logo.png`}
      />
    </a>
  );
}

Logo.defaultProps = {
  title: "ONE TAP NFT",
};
