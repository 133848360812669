import { Upload, message } from "antd";
import { LoadingOutlined, CameraOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { SERVER_URL } from "../constants";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/gif";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/GIF files!");
  }
  const fileSizeMB = file.size / 1024 / 1024;
  if (fileSizeMB >= 10) {
    message.error("ONE TAP NFTs must be smaller than 10MB!");
  }
  return isJpgOrPng && fileSizeMB < 10;
}

export default function ImageUpload({ onChange, disabledImage }) {
  const [loading, setLoading] = useState(false);
  const [imageURL, setImageURL] = useState();
  const serverURL = SERVER_URL;

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      onChange(info.file.response.ipfsURL);
      setLoading(false);
      getBase64(info.file.originFileObj, (url) => {
        setImageURL(url);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      alert("Upload failed. Please try again later.");
    }
  };

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <CameraOutlined style={{ fontSize: 40, margin: 10 }} />
      )}
      <div style={{ marginTop: 8, fontWeight: "bold" }}>Upload</div>
    </div>
  );

  return (
    <div>
      <Upload
        name="nftimage"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action={serverURL + "/upload"}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        disabled={disabledImage}
      >
        {imageURL ? (
          <img src={imageURL} alt="avatar" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </div>
  );
}
