import { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";

import { Row, Col } from "antd";
import "antd/dist/antd.css";
import "antd-country-phone-input/dist/index.css";
import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";

import { FAQ, Logo } from "./components";
import MintForm from "./components/MintForm";

function App(props) {
  return (
    <ConfigProvider locale={en}>
      <div className="App">
        <Row>
          <Col span={24}>&nbsp;</Col>
        </Row>
        <Row>
          <Col span={24}>
            <Logo />
          </Col>
        </Row>
        <Switch>
          <Route exact path="/">
            <Row>
              <Col span={16} offset={4} md={{ span: 8, offset: 8 }}>
                <MintForm />
              </Col>
            </Row>
          </Route>
          <Route exact path="/faq">
            <Row>
              <Col span={16} offset={4} md={{ span: 8, offset: 8 }}>
                <FAQ />
              </Col>
            </Row>
          </Route>
        </Switch>
      </div>
    </ConfigProvider>
  );
}

export default App;
